<template>
  <div class="admin-wrap">
    <AdminMenu active-item="work-detail" />
    <div class="admin-main">
      <AdminHeader title="明細查詢" />
      <div class="work-detail">
        <div class="work-detail__date-picker">
          <input type="date" v-model="start">～<input type="date" v-model="end">
        </div>
        <p class="work-detail__total">案件總數：{{total}}</p>
        <div class="work-detail__wrap">
          <h3>工作明細</h3>
          <div class="work-detail__list work-detail__list--empty" v-if="total===0">
            <div class="work-detail__empty">
              <img src="@/assets/images/icon-empty.svg" alt="沒有資料，請試試其它搜尋條件">
              <p>此時間區段內無資料</p>
            </div>
          </div>
          <div class="work-detail__list" v-if="total > 0">
            <div class="work-detail__work-item" v-for="r in reservations" :key="r">
              <div class="work-detail__row">
                <span class="work-detail__date">日期：{{r.date}}</span>
                <span class="work-detail__status" :class="colorClass(r.status)">{{r.status}}</span>
                <span class="work-detail__reservation">{{r.treatmentName}}</span>
              </div>
              <div class="work-detail__row">
                <span class="work-detail__client-name">{{r.patient}}</span>
                <span class="work-detail__doctor-name">{{r.doctor}}</span>
                <span class="work-detail__start">開始時間：{{r.time}}</span>
                <span class="work-detail__end" v-if="r.status==='已完成'">完成時間：{{r.finishTime}}</span>
                <span class="work-detail__time-consuming" v-if="r.elapsed > 0">耗時：{{r.elapsed}}分鐘</span>
              </div>
            </div>
          </div>
          <div class="admin-pagination" v-if="total > 0">
            <button class="admin-pagination__btn-prev" :class="{ 'is-disable': isFirst }" @click="onPrev">上一頁</button>
            <span class="admin-pagination__current">{{page}}</span>
            <span class="admin-pagination__page">/ {{totalPages}}頁</span>
            <button class="admin-pagination__btn-next" :class="{ 'is-disable': isLast }" @click="onNext">下一頁</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import { formatDate } from '../../utils/utils'
import { apiGetReportReservations } from '@/api/v1/admin'

export default {
  name: 'WorkDetail',
  components: { AdminMenu, AdminHeader },
  computed: {
    dateRangePage () {
      return [this.start, this.end, this.page]
    },
    isFirst () {
      return this.page === 1
    },
    isLast () {
      return this.page === this.totalPages
    },
    totalPages () {
      return Math.ceil(this.total / this.pageSize)
    }
  },
  watch: {
    dateRangePage () {
      if (this.start && this.end) {
        this.fetchData()
      }
    }
  },
  created () {
    const today = new Date()
    this.start = formatDate(today)
    this.end = formatDate(new Date(today.setDate(today.getDate() + 1)))
  },
  data () {
    return {
      start: '',
      end: '',
      total: 0,
      page: 1,
      pageSize: 10,
      reservations: []
    }
  },
  methods: {
    fetchData () {
      const params = {
        start: this.start,
        end: this.end,
        page: this.page,
        size: this.pageSize
      }
      apiGetReportReservations(params).then(data => {
        console.log(data)
        if (!data.error) {
          this.reservations = data.content
          this.total = data.totalItems
        }
      })
    },
    onPrev () {
      this.page--
    },
    onNext () {
      this.page++
    },
    colorClass (status) {
      switch (status) {
        case '已完成':
          return 'is-complete'
        case '逾期未報到':
        case '取消':
          return 'is-timeout'
        default:
          return 'is-undone'
      }
    }
  }
}
</script>

<style>

</style>
